import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import { Box } from '@mui/material';
import wheelplaceLogo from '../../../../assets/45082044.svg';
import { Link } from 'react-router-dom/';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      style={{ display: 'flex', alignSelf: 'center' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: 'none', alignSelf: 'center' }}
        >
          <path
            d="M23.25 11.2709C23.2543 12.8657 22.8688 14.439 22.125 15.8625C21.243 17.5684 19.8872 19.0032 18.2093 20.0062C16.5314 21.0093 14.5978 21.541 12.625 21.5417C10.9752 21.5459 9.34764 21.1732 7.875 20.4542L0.75 22.75L3.125 15.8625C2.38116 14.439 1.9957 12.8657 2 11.2709C2.00076 9.36383 2.55076 7.49465 3.5884 5.8727C4.62603 4.25075 6.11032 2.94009 7.875 2.08753C9.34764 1.36849 10.9752 0.995876 12.625 1.00003H13.25C15.8554 1.13898 18.3163 2.20204 20.1614 3.98566C22.0065 5.76927 23.1063 8.14811 23.25 10.6667V11.2709Z"
            stroke="#2A2273"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className={css.inbox} style={{ color: '#1E2F7B', margin: '0', marginTop: '10px' }}>
          <FormattedMessage id="TopbarDesktop.inbox" />
          {notificationDot}
        </span>
      </div>
    </NamedLink>
  ) : null;

  const listingsLink = (
    <NamedLink
      name="SearchPage"
      className={css.inboxLink}
      style={{ display: 'flex', alignSelf: 'center' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: 'none', alignSelf: 'center' }}
        >
          <path
            d="M12.7222 13.8522V4.96331M12.7222 13.8522L19.5026 17.7266C19.947 17.9806 20.5 17.6598 20.5 17.1479V1.66766C20.5 1.15577 19.947 0.834857 19.5026 1.08882L12.7222 4.96331M12.7222 13.8522H4.94444C2.48984 13.8522 0.5 11.8623 0.5 9.40776C0.5 6.95316 2.48984 4.96331 4.94444 4.96331H12.7222"
            stroke="#2A2273"
          />
          <path
            d="M5.78563 19.7412L4.94434 13.8522H9.38878L10.1412 19.119C10.3306 20.4443 9.30211 21.63 7.9634 21.63C6.86863 21.63 5.94045 20.825 5.78563 19.7412Z"
            stroke="#2A2273"
          />
        </svg>
        <span className={css.inbox} style={{ color: '#1E2F7B', margin: '0', marginTop: '10px' }}>
          <FormattedMessage id="Topbar.listingsTitle" />
        </span>
      </div>
    </NamedLink>
  );

  const contactLink = (
    <Link
      className={css.inboxLink}
      to="/p/kontakt"
      style={{ display: 'flex', alignSelf: 'center', textDecoration: 'none' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: 'none', alignSelf: 'center' }}
        >
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M20.8881 15.9201V18.9201C20.8892 19.1986 20.8322 19.4743 20.7206 19.7294C20.6091 19.9846 20.4454 20.2137 20.2402 20.402C20.035 20.5902 19.7927 20.7336 19.5289 20.8228C19.265 20.912 18.9855 20.9452 18.7081 20.9201C15.631 20.5857 12.6751 19.5342 10.0781 17.8501C7.66194 16.3148 5.61345 14.2663 4.07812 11.8501C2.38809 9.2413 1.33636 6.27109 1.00812 3.1801C0.983127 2.90356 1.01599 2.62486 1.10462 2.36172C1.19324 2.09859 1.33569 1.85679 1.52288 1.65172C1.71008 1.44665 1.93792 1.28281 2.19191 1.17062C2.44589 1.05843 2.72046 1.00036 2.99812 1.0001H5.99812C6.48342 0.995321 6.95391 1.16718 7.32188 1.48363C7.68985 1.80008 7.93019 2.23954 7.99812 2.7201C8.12474 3.68016 8.35957 4.62282 8.69812 5.5301C8.83266 5.88802 8.86178 6.27701 8.78202 6.65098C8.70227 7.02494 8.51698 7.36821 8.24812 7.6401L6.97812 8.9101C8.40167 11.4136 10.4746 13.4865 12.9781 14.9101L14.2481 13.6401C14.52 13.3712 14.8633 13.1859 15.2372 13.1062C15.6112 13.0264 16.0002 13.0556 16.3581 13.1901C17.2654 13.5286 18.2081 13.7635 19.1681 13.8901C19.6539 13.9586 20.0975 14.2033 20.4146 14.5776C20.7318 14.9519 20.9003 15.4297 20.8881 15.9201Z"
            stroke="#2A2273"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className={css.inbox} style={{ color: '#1E2F7B', margin: '0', marginTop: '10px' }}>
          <FormattedMessage id="Topbar.contact" />
        </span>
      </div>
    </Link>
  );

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  // const signupLink = isAuthenticatedOrJustHydrated ? null : (
  //   <NamedLink name="SignupPage" className={css.signupLink}>
  //     <span className={css.signup}>
  //       <FormattedMessage id="TopbarDesktop.signup" />
  //     </span>
  //   </NamedLink>
  // );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="LoginPage"
      className={css.inboxLink}
      style={{ display: 'flex', alignSelf: 'center' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: 'none', alignSelf: 'center' }}
        >
          <path
            d="M26 26V23.2222C26 21.7488 25.3415 20.3357 24.1694 19.2939C22.9973 18.252 21.4076 17.6667 19.75 17.6667H7.25C5.5924 17.6667 4.00268 18.252 2.83058 19.2939C1.65848 20.3357 1 21.7488 1 23.2222V26M19.75 6.55556C19.75 9.6238 16.9518 12.1111 13.5 12.1111C10.0482 12.1111 7.25 9.6238 7.25 6.55556C7.25 3.48731 10.0482 1 13.5 1C16.9518 1 19.75 3.48731 19.75 6.55556Z"
            stroke="#2A2273"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className={css.inbox} style={{ color: '#1E2F7B', margin: '0', marginTop: '10px' }}>
          <FormattedMessage id="TopbarDesktop.login" />
        </span>
      </div>
    </NamedLink>
  );

  return (
    <nav className={classes} style={{ display: 'flex' }}>
      {/* <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      /> */}
      <NamedLink name="LandingPage">
        <Box
          component="img"
          sx={{
            height: '72px',
            width: '295px',
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
            objectFit: 'cover',
          }}
          alt="Wheelplace logo."
          src={wheelplaceLogo}
        />
      </NamedLink>
      {search}
      <NamedLink
        className={css.createListingLink}
        name="NewListingPage"
        style={{
          backgroundColor: '#2A2273',
          color: 'white',
          alignSelf: 'center',
          height: '60%',
          display: 'flex',
          borderRadius: '10px',
        }}
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ alignSelf: 'center', marginRight: '20px' }}
        >
          <path
            d="M18.7 8.5H15.3V15.3H8.5V18.7H15.3V25.5H18.7V18.7H25.5V15.3H18.7V8.5ZM17 0C7.616 0 0 7.616 0 17C0 26.384 7.616 34 17 34C26.384 34 34 26.384 34 17C34 7.616 26.384 0 17 0ZM17 30.6C9.503 30.6 3.4 24.497 3.4 17C3.4 9.503 9.503 3.4 17 3.4C24.497 3.4 30.6 9.503 30.6 17C30.6 24.497 24.497 30.6 17 30.6Z"
            fill="#F3F5F5"
          />
        </svg>
        <span className={css.createListing} style={{ alignSelf: 'center' }}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
      {inboxLink}
      {listingsLink}
      {contactLink}
      {profileMenu}
      {/* {signupLink} */}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
